var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-2" },
    [
      _c("ValidationProvider", {
        attrs: { rules: _vm.rules, immediate: _vm.immediate },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var errors = ref.errors
              return [
                errors.length && _vm.showError
                  ? _c(
                      "p",
                      {
                        staticClass: "text-xs font-bold text-left text-red-600",
                      },
                      [_vm._v(_vm._s(errors[0]))]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass:
                      "grid grid-cols-2 gap-0 rounded border border-gray-200",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-span-1 bg-gray-100 divide-gray-200 border-r border-b",
                      },
                      [
                        _c("p", { staticClass: "font-normal text-left p-2" }, [
                          _vm._v(_vm._s(_vm.$t("commons.fields.area"))),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-span-1 bg-gray-100 divide-gray-200 border-r border-b",
                      },
                      [
                        _c("p", { staticClass: "font-normal text-left p-2" }, [
                          _vm._v(_vm._s(_vm.$t("commons.fields.country"))),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "col-span-1" }, [
                      _c(
                        "table",
                        {
                          staticClass:
                            "divide-y divide-gray-200 w-full border-r",
                        },
                        [
                          _c(
                            "tbody",
                            _vm._l(_vm.allAreas, function (item, index) {
                              return _c("tr", { key: "area_" + index }, [
                                _c(
                                  "td",
                                  {
                                    staticClass: "py-2",
                                    class:
                                      _vm.currentArea === item.value
                                        ? "bg-gray-200"
                                        : "bg-white",
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectArea(item.value)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "FlCheckbox",
                                      {
                                        staticClass: "px-2",
                                        attrs: {
                                          value: _vm.value,
                                          trueValue: _vm.choicesForArea(
                                            item.value
                                          ),
                                          compareValues: ["country"],
                                          disabled:
                                            _vm.disabled ||
                                            _vm.disabledAreas[index] === 0,
                                        },
                                        on: {
                                          input: function ($event) {
                                            _vm.input(
                                              $event,
                                              _vm.choicesForArea(item.value)
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t(item.text)))]
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            }),
                            0
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "col-span-1 h-full relative" }, [
                      _vm.currentArea
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "overflow-y-scroll absolute inset-0 bg-gray-200",
                            },
                            [
                              _c(
                                "table",
                                {
                                  ref: "countries",
                                  staticClass:
                                    "divide-y divide-gray-200 w-full",
                                },
                                [
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.countriesInCurrentArea,
                                      function (country) {
                                        return _c(
                                          "tr",
                                          { key: "country_" + country.country },
                                          [
                                            _c(
                                              "td",
                                              { staticClass: "py-2" },
                                              [
                                                _c(
                                                  "FlCheckbox",
                                                  {
                                                    staticClass: "px-2",
                                                    attrs: {
                                                      value: _vm.value,
                                                      trueValue: country,
                                                      compareValues: [
                                                        "country",
                                                      ],
                                                      disabled:
                                                        _vm.disabled ||
                                                        _vm.isDisabled(
                                                          country.country
                                                        ),
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.input(
                                                          $event,
                                                          null
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(country.text))]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _c(
                            "div",
                            { staticClass: "absolute inset-0 bg-gray-50" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "py-2 text-center relative top-1/2 transform -translate-y-2/4",
                                },
                                [_vm._t("emptyComment")],
                                2
                              ),
                            ]
                          ),
                    ]),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _vm._t("footer"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }